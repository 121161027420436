//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    items() {
      const result = {};
      Object.keys(this.$slots).forEach(slotName => {
        const [i] = slotName.split(":");
        result[i] = true;
      });
      return Object.keys(result);
    }
  }
};