/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Check.vue?vue&type=template&id=46c11902&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports